*{margin: 0;
    padding: 0;
    top:0;
   
}

body{
     background-color: black;
    color: white;

}
h1{padding: 8px;}


.button{
    padding: 4px;
    color:white;
}

p{
    color: rgb(202, 175, 123);
}
.center{
    color: orange;
    display: flex;
    align-items:center ;
    justify-content: center;
    padding: 8px;
}